/* Default navbar styles */

* {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
}

:root {
  --background-color: #f4eee0;
  --text-color: #000;
  --title-color: #000;
}

.dark-theme {
 
  --background-color: #000;
  --text-color: #fff;
  --title-color: #fff;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);

}

.navbar {
  cursor: pointer;
  text-align: left;
  background-color: var(--background-color);
  flex-flow: row nowrap;
  align-items: left;
  flex-wrap: wrap;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

.navbar-toggle {
  cursor: pointer;
  display: none;
}

.brand-logo {
  max-height: 50px;
  max-width: 90px;
  padding-top: 1vh;
}

.mb-0,
.my-0 {
  margin-bottom: 0;
}

.toggle-btn {
  background: black;
  color: #fff;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  list-style: none;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 35px;
  border-radius: 100px;
  cursor: pointer;
  margin-right: 20px;
}

.toggle-btn img {
  width: 25px;
  /* margin-left: 5px; */
}

.navbar-links {
  cursor: pointer;
  display: flex;
  margin-top: auto;
  margin-left: auto;
  padding-right: 30px;
  margin-bottom: auto;
  list-style: none;
  transition: width 0.3s ease;
}

.home-container {
padding-top: 20px;
  position: relative; 
  padding-bottom: 30px;


}
.home-bg{
  background-image: url("./images/Screenshot 2023-06-25 at 22.12.02-min.png");
 background-size: cover;
  background-repeat: no-repeat;
 
}
.home-container a {
  display: flex;
  text-decoration: none;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  text-align: center;
  background-color: rgb(21, 4, 21);
  background: -webkit-linear-gradient(#af2f2f, #f6e635);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 1px;
  font-weight: bold;
  font-size: 24px;
  padding: 20px;
  border: 3px solid rgb(56, 56, 56);
  border-radius: 100px;
  margin-bottom: 2px;
  max-width: 300px;
  /* margin-left: 50px; */
  transition: max-width 0.3s ease;
}

.home-container a:hover {
  background-color: rgb(31, 7, 7);
  text-decoration: underline;
  max-width: 600px;
  justify-content: center;
  text-align: center;
}
.home-container, .spinner-container {
  width:50%;
  display: inline-block;
}

.spinner-container{
  padding-left: 40px;
}


.section-start {
  padding-top: 40px;
}
.home-section-start {
  padding-top: 1px;
}
.sub-head {
  background-color: black;
  text-align: center;
  background: -webkit-linear-gradient(#d75f0e, #1c1475);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 1px;
  font-weight: bold;
}
.sub-head2{
  font-size: 23px;
  margin-top: 10px;
  margin-left: 10px;
  text-align: left;
  color: var(--title-color);
}

.navbar-link {
  cursor: pointer;
  background-color: var(--background-color);
  padding: 20px;
  position: relative;
  overflow: hidden;
}





.navbar-item {
  color: var(--title-color);
  text-decoration: none;
  text-align: center;
}
.navbar-links .navbar-item {
  cursor: none;
  position: relative;
}

.carousel-container {
  position: relative;
}

.carousel-img{
  display: block;
  max-height: 100;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}
.carousel-img.loaded {
  opacity: 1;
}
.carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  
}

.contact-form {
  display: flex;
  color: var(--text-color);
  flex-direction: column;
  gap: 10px;
  align-items: center;
  max-width: 400px;
  margin: 0 auto;
  padding-top: 20px;
}

.contact-form div {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.contact-form label {
  font-weight: bold;
  margin-right: 10px;
}

.contact-form input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #000000;
  border-radius: 20px;
}

.contact-form button {
  display: flex;
  padding: 10px 20px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.contact-form button:hover {
  background-color: #45a049;
}

.App {
 
  cursor: auto;
  background-color: var(--background-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: rgb(255, 255, 255);
}

.footer {
  background-color: #222;
  color: #fff;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 75px;
}

.footer-contact {
  flex-basis: 100%;
  margin-bottom: 10px;
  text-align: center;
  justify-content: center;
  
}

.footer-contact .icon-text a {
  color: #fff;
  text-decoration: none;
}

.footer-contact .icon-text a:hover {
  text-decoration: underline;
}

.footer-contact .icon-text a:focus,
.footer-contact .icon-text a:active {
  outline: none;
  text-decoration: underline;
}

.footer-contact .icon-text a:focus:not(:focus-visible) {
  text-decoration: none;
}
.footer-contact p {
  font-size: 16px;
  white-space: pre-line;
  margin: 0;
  text-align: center;
}

.footer-contact span {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 8px;
}

.footer-contact .icon {
  margin-right: 5px;
  vertical-align: middle;
  color: #d75f0e;
}

.footer-contact .icon-text {
  display: inline-block;
  vertical-align: middle;
  padding-left: 5px;
}

.footer-contact .icon-text span {
  display: inline-block;
  vertical-align: middle;
}

.footer-copyright {
  font-size: 14px;
  opacity: 0.8;
  color: #9970f8;
  text-align: right;
}

@media (max-width: 768px) {
  .footer {
    justify-content: center;
    text-align: left;
  }
  
  .footer-contact {
    margin-bottom: 20px;
    text-align: center;
    justify-content: center;
  }
}




.contact-para{
  padding-top: 20px;
}
@media (max-width: 768px) {
  .para-2{
    display: none;
  }
 
  .navbar {
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
  }
  
 .spinner-container{
  display: none;
 }
 .home-container{
  width: 100%;
 }
  .navbar-links {
    display: none;
  }

  .navbar-left {
    display: flex;
    align-items: center;
  }


  .toggle-btn {
    margin-right: 1rem;
  }

  .navbar-toggle {
    display: block;
    width: 30px;
    height: 30px;
    cursor: pointer;
    background: transparent;
    border: none;
    padding: 0;
    margin-right: 3px;
  }


  
  .navbar-toggle img {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 768px) {
  .navbar-left {
    display: flex;
    justify-content: space-between;
  }

  .navbar-center {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 768px) {
  .navbar {
    padding: 0;
  }

  
  .navbar-links {
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #000000;
    list-style: none;
    padding: 0.5rem 0;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
  }

  .navbar-links.open {
    opacity: 1;
    pointer-events: auto;
  transition: max-height 0.5s smooth;
  animation-duration: 0.5s;
  animation-fill-mode: both;
    animation-name: dropdownAnimation;
    
  }
  body.navbar-links-open {
    overflow: hidden;
  }
   .navbar-links.closed {
    animation: dropdownRollUpAnimation 0.5s forwards;

  } 
  @keyframes dropdownRollUpAnimation {
    from {
      max-width: 500px; /* Adjust this value to fit your dropdown content */
    }
    to {
      max-width: 0;
    }
  }
  
  @keyframes dropdownAnimation {
    from {
      max-width: 0;
    }
    to {
      max-width: 500px; /* Adjust this value to fit your dropdown content */
    }
  }
}

@media (min-width: 768px) {
  .navbar-toggle {
    display: none;
  }
}

.navbar-links.open {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  width: 34%;
  text-align: left;
  background-color: var(--background-color);
  height: 100vh;
  

}
.navbar-center-open {
  padding-left: 5vw; /* Adjust the padding value as needed */
}
.navbar-link {
  display: flex;
  padding: 10px;
  justify-content: left;
  align-items: left;
  color: #2c3d80;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-family: Arial, Helvetica, sans-serif;
}

.navbar-link:hover{
  padding: 10px;
  color: purple;
}
.navbar-link.active
{
  padding: 10px;
  color: purple;
}
.navbar-logo {
  /* margin-left: 180px; */
}




#services {
  padding: 30px 0;
}

.services-list {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
}
@media (max-width: 768px) {
  .services-list {
    grid-template-columns: 1fr;
  }
}


.services-list div {
  background: grey;
  padding: 40px;
  font-size: 15px;
  font-weight: 300;
  border-radius: 30px;
  transition: 0.5s, transform 0.5s;
}

.services-list div i {
  font-size: 50px;
  margin-bottom: 30px;
}

.services-list div h2 {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 15px;
}

.services-list div a {
  text-decoration: none;
  color: #fff;
  font-size: 12px;
  margin-top: 20px;
  display: inline-block;
}

.services-list div:hover {
  background: #3e2093;
  transform: translateY(-10px);
}

.header-text h1 {
  font-family: Arial, Helvetica, sans-serif;
  background: white;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-size: 80px;
  margin-top: 20px;
  margin-left: 20px;
  margin-bottom: 30px;
}

.header-text h1 span {
  color: rgb(255, 255, 255);
}
@media (max-width: 768px) {
  .header-text h1 {
    font-size: 50px;
    margin-bottom: 10px;
  }

}
 /* .cursor{
  pointer-events: none;
  
  top: 0;
left: 0;
/* background-color: #000; */
  /* width: 30px;
  height: 30px;
  border: 2px solid white;
  border-radius: 50%;
  position: absolute;
  z-index: 9999;
  transition-duration: 10ms ;
  transition-timing-function: ease-out;
  animation: cursorAnim .5s infinite alternate;
}
.cursor::after{
  content: "";
  width: 30px;
  height: 30px;
  position: absolute;
  border: 7px solid gray;
  border-radius: 50%;
  opacity: .5;
  top: -9px;
  left: -9px;
  animation: cursorAnim2 .5s infinite alternate;
}
@keyframes cursorAnim{
  from{
    transform: scale(1);
  }
  to{
    transform: scale(.7);
  }
}
@keyframes cursorAnim2{
  from{
    transform: scale(1);
  }
  to{
    transform: scale(.4);
  }
}
@keyframes cursorAnim3{
  0%{
    transform: scale(1);
  }
  50%{
    transform: scale(3);

  }
  100%{
    transform: scaleX(1);
    opacity: 0;
  }
}
.expand{
  animation: cursorAnim3 0.5s forwards;
  border: 1px solid blue;
} */   
.container{
  align-items: center;
  justify-content: center;
  width: 85%;
  background: var(--background-color);
  color: var(--text-color);
  border-radius: 6px;
  padding: 20px 20px 20px 20px;
  box-shadow: 10px 10px 10px 10px rgba(119, 119, 119, 0.2);
  margin: 10px 0px 30px 90px;
}
.container h2{
  align-items: center;
}

.container .content{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* .container .content .left-side{
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  position: relative;
}
.content .left-side::before{
  content: '';
  position: absolute;
  height: 70%;
  width: 2px;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
  background: #afafb6;
}
.content .left-side .details{
  margin: 14px;
  text-align: center;
}
.content .left-side .details i{
  font-size: 30px;
  color: #3e2093;
  margin-bottom: 10px;
}
.content .left-side .details .topic{
  font-size: 18px;
  font-weight: 500;
}
.content .left-side .details .text-one,
.content .left-side .details .text-two{
  font-size: 14px;
  color: #afafb6;
} */
.container .content .right-side{
  width: 75%;
  margin-left: 70px;
}
.content .right-side .topic-text{
  font-size: 23px;
  font-weight: 600;
  color: #3e2093;
}
.right-side .input-box{
  height: 50px;
  width: 100%;
  margin: 12px 0;
}
.right-side .input-box input,
.right-side .input-box textarea{
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  background: #e2e2e2;
  border-radius: 6px;
  padding: 0 15px;
  resize: none;
}
.right-side .message-box{
  min-height: 110px;
}
.right-side .input-box textarea{
  padding-top: 6px;
}
.right-side .button{
  display: inline-block;
  margin-top: 12px;
}
.right-side .button {
  color: #fff;
  font-size: 18px;
  outline: none;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  background: #3e2093;
  cursor: pointer;
  transition: all 0.3s ease;
}
.button :hover{
  background: #5029bc;
}
@media (max-width: 900px) {
  .container{
    width: 90%;
    padding: 20px 20px 20px 20px;
    margin-left: 30px;
  }
  .container .content .right-side{
   width: 75%;
   margin-left: 55px;
}
}
/* @media (max-width: 900px) {
  .container{
    border:none;
  }
  .container .content{
    flex-direction: column-reverse;
  }
 .container .content .left-side{
   width: 100%;
   flex-direction: column;

   margin-top: 5px;
   justify-content: center;
   flex-wrap: wrap;
 }
 .container .content .left-side::before{
   display: none;
 }

} */
.image{
  background-color: var(--background-color);
  display: flex;
  border-radius: 12px 0 0 12px;
  color: var(--text-color);
}
.image img{
height:50vh;
margin:50px auto
}
.about-content,.sis-content{
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  border-radius: 0  12px 12px 0;
  color: var(--title-color);
  margin-bottom: 30px; 
  margin: 0 30px 0 30px;
}

.extra-space{
  padding-bottom: 100px;
}
.about-content  p{
  color: var(--text-color);
  padding-bottom: 5px;
  text-align: justify;
  margin: 0 auto;
  opacity: 0.7;

}
.sis-content p{
  color: var(--text-color);
  text-align: justify;
  margin: 0 auto;
  opacity: 0.7;
}

.links{
  margin: 15px 0;
}
.links li{
  color: var(--text-color);
  border: 2px solid #4158D0;
  list-style: none;
  border-radius: 5px;
  padding: 10px 15px;
  width: 160px;
  text-align: center;
}
.links li a{
  color: var(--text-color);
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
}
.links li:hover{
  color: var(--text-color);
  border-color: #C850C0;
}

.vertical-line{
  height: 30px;
  width: 3px;
  background: #C850C0;
  margin: 0 auto;
}




/*****************/

@media(max-width: 900px){
  section{
      grid-template-columns: 1fr;
      width: 100%;
      border-radius: none;
  }
  .image{
      height: 100vh;
      border-radius: none;
  }
  .content{
      height: auto;
      border-radius: none;
  }
  .content h2{
      font-size: 20px;
      margin-top: 50px;
  }
  .content span{
      margin: 20px 0;
  }
  .content p{
      font-size: 14px;
  }
  .links li a{
      font-size: 14px;
  }
  .links{
      margin: 5px 0;
  }
  .links li{
      padding: 6px 10px;
  }
  .icons li i{
      font-size: 15px;
  }
}
.credit{
  text-align: center;
  color: #000;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.text-center{
  text-align: center;
  color: var(--text-color);
  padding-top: 30px;

}
.credit a{
  text-decoration: none;
  color:#000;
  font-weight: bold;
} 
.careers1 p{
  color: var(--text-color);
}
/*bubble button*/
.button-container {
  position: relative;
  width: 100%;
  justify-content: center;

}

/* @media  (max-width: 768px) { */
  .scroll-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
   
    
  }
  
  @media  (max-width: 1000px) {
    .button-container{
      flex-direction: column;
     
    }
    .scroll-container{
      flex-direction: column;
     
    }
    
  }


/* .paused {
  animation-play-state: paused;
} */


.work-img{
  width: 60%;
 
}
@media  (max-width: 900px) {
  .work-img{
    width: 80%;
   
  }
}
.bubble {
  display: inline-block;
  height: -40px;
  border-radius: 25px;
  background-color: rgb(125, 125, 125);
  color: rgb(0, 0, 0);
  text-align: center;
  line-height: 50px;
  margin: 30px;
  cursor: pointer;
  padding: 10px 30px; 
  transition: transform 0.3s;
  pointer-events: auto;
}

.bubble:hover {
  transform: scale(1.1);
  background-color: #3e2093;
  
}


.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(233, 181, 254);
  color: #000;
  border: 1px solid;
  padding: 20px;
  border-radius: 10px;
  z-index: 9999;
  opacity: 0;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  width:500px;
}


.popup.open {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
  animation: popupOpen 0.3s ease-in-out;
 
}
body.popup-open {
  overflow: hidden;
}

.popup-content {
  display: flex;
  align-items: center;
}

.popup-text {
  flex: 1;
}

.popup h3 {
  font-family: Arial, Helvetica, sans-serif;
  background: linear-gradient(to right, #302828,red,
           #b700a5);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  margin-bottom: 40px;
}

.popup h5 {
  margin-bottom: 10px;
  color: #6a6a6a;
}

.popup-img {
  height: 200px;
  margin-left: 20px;
}
.loading {
  width: 40px;
  height: 40px;
  margin: 20px auto;
  border: 4px solid #ff0000;
  border-top: 1px solid #68bbfa;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.popup-img {
  display: none;
}

.popup-img.loaded {
  display: block;
}
@media  (max-width: 765px) {
  .popup-img{
    height: 100px;
  }
  .popup{
    width: 300px;
  }
}

.popup button {
  position: absolute;
  background-color: rgb(179, 41, 41);
  top: 10px;
  right: 10px;
  color: rgb(130, 127, 127);
  border-radius: 1px;
  padding: 5px 10px;
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 9998;
}
.navbar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Adjust the background color and opacity as needed */
  z-index: 999; /* Adjust the z-index value to make sure the overlay appears above other elements */
}
/* .wrapper{
  max-width: 100;
  margin:0 auto;
} */
/* carsoel */
.carousel .slide img {
  width: 100%; /* Set the image width to 100% of the container */
  object-fit: cover;
}
@media (max-width: 768px) {
.bubble{
  margin: 10px;
}}
@media (min-width: 768px) {
  /* Adjust the breakpoint and image size as needed */
  .carousel .slide img {
    height: 500px; /* Set the desired image height for larger screens */
  }
  
}



